.mobile-bottom-button {
    display: none;
  }
  
  .mobile-top-button {
    display: flex;
  }
  
  .mobile-top-button button{
    background-color: #007bff;
    color: #fff;
    border: none;
    margin-top: 10px;
    padding: 2%;
    cursor: pointer;
    border-radius: 5px;
    font-size: 30px;
    font-weight: bold;
  }
  
  .quiz-title {
    font-size: 24px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  
  .quiz-result-container {
    max-width: 600px;
    margin: 20px auto;
    background-color: rgb(238, 238, 238);
    padding: 20px;
    margin: 20px;
  }
  
  .quiz-result-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .quiz-result-container p {
    font-size: 18px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .quiz-result-container h2 {
    font-size: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .quiz-result-container ul {
    list-style: none;
    padding: 0;
     
  }
  
  .quiz-result-container li {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgb(235, 235, 187);
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }
  
  .score-display-container {
    display: flex;
    flex-direction: column;
  }
  
  .score-display-container img{
    display: flex;
    max-width: 50%;
    padding-left: 25%;
  }
  
  .score-display-container p{
    font-size: 30px;
    font-weight: 400;
  }
    
  
  .answer-list {
    margin-left: 5%;
    display: grid;
    grid-template-columns: repeat(2, 300px);
    gap: 10px;
  }
  
  .quiz-result-container li p {
    font-size: 20px;
    font-weight: bold;
    text-align: center; /* Center the text horizontally */
    display: flex;
    align-items: center; /* Center the text vertically */
    height: 100%; /* Ensure the text takes the full height of the list item */
    padding-bottom: 20px;
  }
  
  .answer-list li.correct-answer-option {
    height: 40px; /* Adjust the height as needed */
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgb(23,236,24);
  }
  
  .answer-list li.incorrect-answer-option {
    height: 40px; /* Adjust the height as needed */
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgb(212, 80, 80);
  }
  
  .answer-list li.regular-answer-option {
    height: 40px; /* Adjust the height as needed */
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgb(241, 238, 238);
  }
  
  
  .standard-answer {
    background-color: white;
  }
  
  .selected-answer {
    background-color: lightblue;
  }
  
  .correct-answer-icon {
    color: green;
    margin-left: 5px;
  }
  
  .incorrect-answer-icon {
    color: red;
    margin-left: 5px;
  }
  
  @media (max-width: 768px) {
  
    .mobile-bottom-button {
      display: flex;
      padding: 5%;
  
    }
  
    .mobile-top-button {
      display: flex;
      padding: 5px;
    }
  
    .quiz-title {
      font-size: 24px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .mobile-top-button button{
      background-color: #007bff;
      color: #fff;
      border: none;
      margin-top: 10px;
      padding: 2%;
      cursor: pointer;
      border-radius: 5px;
      font-size: 30px;
      font-weight: bold;
    }
  
    .mobile-bottom-button button {
      background-color: #007bff;
      color: #fff;
      border: none;
      margin: 10px;
      cursor: pointer;
      border-radius: 5px;
      font-size: 30px;
      font-weight: bold;
    }
    
    .quiz-result-container {
      background-color: rgb(238, 238, 238);
      padding: 0px 20px 0px 20px;
      margin: 10px;
      max-width: 100%;
    }
    
    .quiz-result-container h1 {
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .quiz-result-container p {
      font-size: 18px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .quiz-result-container h2 {
      font-size: 20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .quiz-result-container ul {
      list-style: none;
      padding: 0;
       
    }
    
    .quiz-result-container li {
      margin-bottom: 20px;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      background-color: rgb(235, 235, 187);
      font-weight: bold;
      text-align: center;
      font-size: 18px;
    }
    
    .score-display-container img{
      max-height: 250px;
      width: auto;
    }
    
    .score-display-container p{
      font-size: 30px;
      font-weight: 400;
      text-align: center;
    }
      
    
    .answer-list {
      display: grid;
      grid-template-columns: repeat(2, 40%);
      gap: 10%;
      margin-left: 7%;
    }
    
    .quiz-result-container li p {
      font-size: 20px;
      font-weight: bold;
      text-align: center; /* Center the text horizontally */
      display: flex;
      align-items: center; /* Center the text vertically */
      height: 100%; /* Ensure the text takes the full height of the list item */
      padding-bottom: 20px;
    }
    
    .answer-list li.correct-answer-option {
      height: auto; /* Adjust the height as needed */
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: rgb(23,236,24);
    }
    
    .answer-list li.incorrect-answer-option {
      height: auto; /* Adjust the height as needed */
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: rgb(212, 80, 80);
    }
    
    .answer-list li.regular-answer-option {
      height: auto; /* Adjust the height as needed */
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: rgb(241, 238, 238);
    }
    
    
    .standard-answer {
      background-color: white;
    }
    
    .selected-answer {
      background-color: lightblue;
    }
    
    .correct-answer-icon {
      color: green;
      margin-left: 5px;
    }
    
    .incorrect-answer-icon {
      color: red;
      margin-left: 5px;
    }
  }
  
  @media (max-width: 480px) {
  
    .quiz-title {
      font-size: 24px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .mobile-bottom-button {
      display: flex;
    }
  
    .mobile-top-button {
      display: flex;
      margin-top: 10px;
    }
  
    .mobile-top-button button{
      background-color: #007bff;
      color: #fff;
      border: none;
      padding: 10px 20px;
      margin: 5px;
      cursor: pointer;
      border-radius: 5px;
      font-size: 20px;
      font-weight: bold;
    }
  
    .mobile-bottom-button button {
      background-color: #007bff;
      color: #fff;
      border: none;
      padding: 5px 10px;
      margin: 5px;
      cursor: pointer;
      border-radius: 5px;
      font-size: 16px;
      font-weight: bold;
    }
    
    .quiz-result-container {
      background-color: rgb(238, 238, 238);
      padding: 0px 20px 0px 20px;
      max-width: 100%;
    }
    
    .quiz-result-container h1 {
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .quiz-result-container p {
      font-size: 18px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .quiz-result-container h2 {
      font-size: 20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .quiz-result-container ul {
      list-style: none;
      padding: 0;
       
    }
    
    .quiz-result-container li {
      margin-bottom: 20px;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      background-color: rgb(235, 235, 187);
      font-weight: bold;
      font-size: 18px;
    }
    
    .score-display-container img{
      max-height: 250px;
      width: auto;
    }
    
    .score-display-container p{
      font-size: 30px;
      font-weight: 400;
    }
      
    
    .answer-list {
      display: grid;
      grid-template-columns: repeat(2, 40%);
      gap: 40px;
      margin-left: 7%;
    }
    
    .quiz-result-container li p {
      font-size: 20px;
      font-weight: bold;
      text-align: center; /* Center the text horizontally */
      display: flex;
      align-items: center; /* Center the text vertically */
      height: 100%; /* Ensure the text takes the full height of the list item */
      padding-bottom: 20px;
    }
    
    .answer-list li.correct-answer-option {
      height: auto; /* Adjust the height as needed */
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: rgb(23,236,24);
    }
    
    .answer-list li.incorrect-answer-option {
      height: auto; /* Adjust the height as needed */
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: rgb(212, 80, 80);
    }
    
    .answer-list li.regular-answer-option {
      height: auto; /* Adjust the height as needed */
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: rgb(241, 238, 238);
    }
    
    
    .standard-answer {
      background-color: white;
    }
    
    .selected-answer {
      background-color: lightblue;
    }
    
    .correct-answer-icon {
      color: green;
      margin-left: 5px;
    }
    
    .incorrect-answer-icon {
      color: red;
      margin-left: 5px;
    }
  
  }