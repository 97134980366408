@media (min-width: 769px) {

  .team-buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-left: auto;
      margin-right: auto;
      max-width: 1300px;
  
    }
    
    .team-button {
      display: flex;
      align-items: column;
      justify-content: center;
      border: none;
      background-color: white;
      padding: 10px;
      margin: 5px;
      cursor: pointer;
      outline: none;
      width: 160px;
    }
  
    .team-button img {
      height: 160px;
    }
    
    .team-button.selected {
      background-color: #0fd33a;
    }
    
    .difficulty-buttons {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    
    .difficulty-button {
      background-color: #faf8f8;
      border: none;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 10px;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 40px
    }
  
    
    .difficulty-button.selected {
      background-color: #0fd33a;
    }
  
    .submit-button {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      max-height: 300px;
      width: auto;
    }
  
    .submit-button img {
      background-color: white;
      max-width: 300px;
    }
  
    .submit-button img:hover {
      background-color: gray;
    }
  
    .page-title {
      display: inline-block;
      justify-content: center;
      background-color: white;
      padding: 15px;
      margin-bottom: 50px;
      margin-top: 0px;
  
    }
  
    .title-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 40px;
    }
  }

@media (max-width: 768px) {
  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 20px;
    padding-top: 10px;
  }

  .non-standard-team img {
    height: 70%;
    width: 60%;
    max-height: 100px;
  }

  .title-image {
    max-width: 300px;
  }


  .team-button {
     /* Adjust the margin and flex properties to center align and space out the buttons */
  }

  .team-buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr)); /* Create a flexible grid with a minimum column width of 150px */
    grid-template-rows: repeat(5, 1fr);
    gap: 10px; /* Add some gap between buttons */
    margin: 20px auto; /* Center align the buttons and add margin */
    margin-left: 5px;
    margin-right: 5px;
    max-width: 100%;

  }

  .team-button img {
    max-width: 70px;
    max-height: 70px; /* Make the image fill the width of the button */
  }

  .team-button.selected {
    background-color: #0fd33a;
  }
  
  .difficulty-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .difficulty-button {
    background-color: #7c7a7a;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 40px
  }

  
  .difficulty-button.selected {
    background-color: #0fd33a;
  }

  .submit-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    max-height: 300px;
    width: auto;
  }

  .submit-button img {
    background-color: white;
    max-width: 300px;
  }

  .submit-button img:hover {
    background-color: gray;
  }

  .page-title {
    display: inline-block;
    justify-content: center;
    background-color: white;
    padding: 15px;
    margin-bottom: 50px;
    margin-top: 0px;

  }
}
@media (max-width: 480px) {
  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    padding-top: 10px;
  }

  .non-standard-team img {
    height: 70%;
    width: 60%;
    max-height: 100px;
  }

  .title-image {
    max-width: 300px;
  }

  .team-button {
     /* Adjust the margin and flex properties to center align and space out the buttons */
  }

  .team-buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)); /* Create a flexible grid with a minimum column width of 150px */
    grid-template-rows: auto;
    gap: 10px; /* Add some gap between buttons */
    margin: 20px auto; /* Center align the buttons and add margin */
    margin-left: 5px;
    margin-right: 5px;
    max-width: 100%;

  }

  .team-button img {
    max-width: 70px;
    max-height: 70px; /* Make the image fill the width of the button */
  }

  .team-button.selected {
    background-color: #0fd33a;
  }
  
  .difficulty-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .difficulty-button {
    background-color: #7c7a7a;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 40px
  }

  
  .difficulty-button.selected {
    background-color: #0fd33a;
  }

  .submit-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    max-height: 300px;
    width: auto;
  }

  .submit-button img {
    background-color: white;
    max-width: 300px;
  }

  .submit-button img:hover {
    background-color: gray;
  }

  .page-title {
    display: inline-block;
    justify-content: center;
    background-color: white;
    padding: 15px;
    margin-bottom: 50px;
    margin-top: 0px;

  }
}
