@media (max-width: 768px) {
  .navigationBar {
    background-color: #000000; /* Green color */
    height: 40px;
    display: flex;
    justify-content: space-between;
    z-index: 100;
  }
  
  .navigationBar .title {
    color: #0aeb55; /* White color */
    font-weight: bold;
    max-width: 100%;
    margin-top: 20px;
  }
  
  .navigationBar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .navigationBar img {
    max-width: 100%;
  }

  .navigationBar ul {
    display: none;
  }

  .navigationBar li {
    margin-left: 10px;
  }
  
  .navigationBar a {
    text-decoration: none;
    color: #ffffff; /* White color */
    font-size: 20px;
  }
}

@media (min-width: 768px){
  .navigationBar {/* Green color */
    height: 40px;
    display: flex;
    justify-content: space-between;
    z-index: 100;
    align-items: center;
  }
  
  .navigationBar .title {
    color: #0aeb55; /* White color */
    font-weight: bold;
    max-width: 100%;
    margin-left: calc(50% - 400px);
    margin-top: 20px;
  }
  
  .navigationBar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .navigationBar img {
    max-width: 100%;
  }

  .navigationBar ul {
    display: none;
  }

  .navigationBar li {
    margin-left: 10px;
  }
  
  .navigationBar a {
    text-decoration: none;
    color: #ffffff; /* White color */
    font-size: 20px;
  }
}

@media (min-width: 1300px) {
  .navigationBar {
    background-color: #000000; /* Green color */
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
  }
  
  .navigationBar .title {
    font-weight: bold;
    color: #ffffff; /* White color */
    max-height: 70px;
    margin-left: 0px;
    margin-top: 0px;
  }
  
  .navigationBar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
.title {
  font-size: 10px;
}

  .navigationBar li {
    margin-left: 0px;
  }
  
  .navigationBar a {
    text-decoration: none;
    color: #ffffff; /* White color */
    font-size: 30px;
    max-height: 70px;
  }

  .navBarLink {
    padding: 10px;
  }

  .navigationBar img {
    max-height: 70px;
  }
  
  .navigationBar a:hover {
    background-color: #006600; /* Darker green color on hover */
  }
}