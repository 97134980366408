@media (max-width: 768px) {
  .question-count-text {
    font-weight: bold;
  }
  /*.main-quiz-answer-list {
    list-style: none;
    display: grid;
    grid-template-columns: 30% 70%; // Two columns: 30% and 70% 
    grid-template-rows: 20% 30% 50%; // Three rows: 20%, 30%, and 50% 
    grid-gap: 10px; // Optional gap between grid items 
    height: 300px;
  }*/

  .main-quiz-mobile-top-button button{
    background-color: #007bff;
    color: #fff;
    border: solid white;
    padding: 20px 20px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 3vw;
    font-weight: bold;
  }

  .main-quiz-mobile-top-button {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .main-quiz-mobile-bottom-button {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .main-quiz-mobile-bottom-button button {
    background-color: #007bff;
    color: #fff;
    border: solid white;
    padding: 30px 30px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 3vw;
    font-weight: bold;
  }


  .quiz-page-container {
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 100px);
  }

  .main-quiz-answer-list {
    width: 80%;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-items: center;
    margin-right: 5%;
  }

  .main-quiz-answer-list li {
    width: 100%;
    height: 100px;
  }

  .quiz-container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-right: 5%;
    margin-left: 5%;
    width: 100%;
  }

  .question-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #007bff;
    padding: 5%;
    padding-top: 2%;
    width: 90%;


  }

  .question-image-container {
    overflow: hidden;
    /* Hide any content that overflows the container */
    margin-top: 10px;
    margin-bottom: 20px;
    padding-top: 20px;
    /* Add some margin to separate the image from the text */
    width: 90%;
    height: 250px;

  }

  .question-image {
    overflow: hidden;
    /* Hide any content that overflows the container */
  }

  .question-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    height: 100px;
  }

  .question-text {
    line-height: 1.1;
    background-color: white;
    padding: 5px;
    max-width: 90%;
    font-size: 2vw;
    text-align: center;
    width: 100%;
    height: auto;
  }

  .option-button-container {
    gap: 10px;
    align-items: center;
    width: 100%;
  }

  .option-button {
    border: none;
    border-radius: 8px;
    background-color: #f0f0f0;
    font-size: 4vw;
    font-weight: bold;
    color: #333;
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 769px) {


  .quiz-page-container {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .main-quiz-mobile-top-button button{
    background-color: #007bff;
    color: #fff;
    border: solid white;
    padding: 20px 20px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1.5vw;
    font-weight: bold;
  }

  .main-quiz-mobile-top-button {
    margin-top: 40px;
  }

  .main-quiz-mobile-bottom-button {
    margin-bottom: 20px;
  }

  .main-quiz-mobile-bottom-button button {
    background-color: #007bff;
    color: #fff;
    border: solid white;
    padding: 30px 30px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1.5vw;
    font-weight: bold;
  }

  .main-quiz-answer-list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 0px;
    justify-items: center;
    padding: 0;
  }

  .quiz-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 20px;
    width: 60%;
    margin-bottom: 20px;
  }

  .question-count-text {
    margin: 0px;
  }

  .question-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*background-image: linear-gradient(to bottom, #6cd86c, #514fce);*/
    background-color: #007bff;
    padding: 20px;
    width: 100%;
    border: 5px solid #ffffff;

  }

  .question-image-container {
    overflow: hidden;
    /* Hide any content that overflows the container */
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
    padding-top: 20px;
    /* Add some margin to separate the image from the text */
    height: 400px;
    width: 580px;
  }

  .question-image {
    height: 100%;
    width: 100%;
  }

  .question-text {
    line-height: 1.1;
    background-color: white;
    padding: 5px;
    width: 100%;
    text-align: center;
  }

  .question-text-container {
    width: 90%;
    height: 170px;
  }

  .question-count-text {
    font-weight: bold;
  }

  .option-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    /* Set the width of the buttons */
    height: 80px;
    /* Set the height of the buttons */
    margin: 5px;
    border: none;
    /* Remove the border */
    border-radius: 8px;
    /* Round the corners of the buttons */
    background-color: #f0f0f0;
    /* Add a background color to the buttons */
    font-size: 25px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* Add a transition effect for hover */
  }

  .option-button.selected {
    background-color: #007bff;
    /* Change the background color for the selected button */
    color: #fff;
    /* Change the text color for the selected button */
  }

  .option-button:hover {
    background-color: #c0c0c0;
    /* Change the background color for hover */
    color: #333;
    /* Change the text color for hover */
  }
}

@media (min-width: 1300px){
  .option-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    /* Set the width of the buttons */
    height: 80px;
    /* Set the height of the buttons */
    margin: 5px;
    border: none;
    /* Remove the border */
    border-radius: 8px;
    /* Round the corners of the buttons */
    background-color: #f0f0f0;
    /* Add a background color to the buttons */
    font-size: 25px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* Add a transition effect for hover */
  }
  .main-quiz-mobile-top-button button{
    background-color: #007bff;
    color: #fff;
    border: solid white;
    padding: 20px 20px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1vw;
    font-weight: bold;
  }

  .main-quiz-mobile-top-button {
    margin-top: 20px;
  }

  .main-quiz-mobile-bottom-button {
    margin-bottom: 20px;
  }

  .main-quiz-mobile-bottom-button button {
    background-color: #007bff;
    color: #fff;
    border: solid white;
    padding: 30px 30px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1vw;
    font-weight: bold;
  }
}