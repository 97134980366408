
@media (max-width: 768px) {
.prem-team-selector-button:hover{
  background-color: #007bff;
}
.league-buttons-container {
  justify-content: center;
  display: flex;
  width: 100vw;
}

.home-page-info-text {
  display: none;
}

.home-container {
    display: flex;
    flex-direction: column;
    padding-top: 50px; /* Adjust the height as needed */
  }

  .home-page-title {
    font-size: 50px;
    color: blue;
    margin-bottom: 10px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-family: 'Permanent Marker', cursive;
    font-family: 'Roboto', sans-serif;
    background-color: white;
  }

.title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.league-buttons {
  /*display: flex;
  flex-direction: row;
  max-width: 100vw;*/
  display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr)); /* Create a flexible grid with a minimum column width of 150px */
    grid-template-rows: repeat(2, 1fr);
    gap: 10px; /* Add some gap between buttons */
    margin: 20px auto; /* Center align the buttons and add margin */
    margin-left: 5px;
    margin-right: 5px;
    max-width: 100%;
    justify-content: center;
}

.leagues-title {
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
  max-width: 100%;
}

.leagues-title img {
  max-width: 100%;
}

.home-page-or-separator {
  display: flex;
  margin: 3%;
  max-width: 100%;
  justify-content: center;
}

.home-page-or-separator img {
  max-width: 10%;
}


.teams-title {
  display: flex;
  justify-content: center;
  margin: 3%;
  max-width: 100%;
}

.teams-title img {
  max-width: 100%;
}

.league-button {
  display: flex;
  align-items: column;
  justify-content: center;
  border: none;
  background-color: white;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  outline: none;
}

.league-button img {
  max-width: 70px;
  max-height: 70px;
}

.non-standard-league-image {
  min-width: 180%;
  height: 100%;
}

.prem-team-selector-button img {
  width: 100%;
  height: 100%;
}

.prem-team-selector-button {
  max-height: 40%;
  max-width: 40%;
  display: flex;
  justify-content: center;
}

.team-selector-button {
  display: flex;
  justify-content: center;
  max-width: 100vw;
  max-height: 10%;
}

.title-image-container {
  display: flex;
  margin: 10px;
  width: auto;
  height: 500px;
}

.league-button:hover{
  background-color: #007bff;
}

.image-container {
  display: flex;
  justify-content: center;
}

.image-container img {
  margin: 10px;
  width: auto;
  padding: 50px;
  max-height: 300px;
}
}

@media (min-width: 768px) {
  .prem-team-selector-button:hover{
    background-color: #007bff;
  }
  .league-buttons-container {
    justify-content: center;
    display: flex;
  }

  .home-page-info-text {
    color: white;
  }
  
  .home-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 50px; /* Adjust the height as needed */
    }
  
    .home-page-title {
      font-size: 50px;
      color: blue;
      margin-bottom: 10px;
      font-family: 'IBM Plex Sans', sans-serif;
      font-family: 'Permanent Marker', cursive;
      font-family: 'Roboto', sans-serif;
      background-color: white;
    }
  
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .league-buttons {
    display: flex;
    flex-direction: row;
  }
  
  .leagues-title img{
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
    max-width: 100%;
  }
  
  .home-page-or-separator {
    display: flex;
    justify-content: center;
    margin: 2%;
  }
  
  .teams-title {
    display: flex;
    justify-content: center;
    margin: 3%;
  }

  .teams-title img {
    max-width: 100%;
  }
  
  .league-button {
    display: flex;
    align-items: column;
    justify-content: center;
    border: none;
    background-color: white;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    outline: none;
    width: 160px;
  }
  
  .league-button img {
    max-width: 120px;
    max-height: 120px;
  }
  
  .non-standard-league-image {
    min-width: 130%;
    height: 100%;
  }
  
  .prem-team-selector-button img {
    width: auto;
    height: 200px;
  }
  
  .prem-team-selector-button {
    max-height: 30%;
    max-width: 30%;
    display: flex;
    justify-content: center;
  }
  
  .team-selector-button {
    display: flex;
    justify-content: center;
  }
  
  .title-image-container {
    display: flex;
    margin: 10px;
    width: auto;
    height: 500px;
  }
  
  .league-button:hover{
    background-color: #007bff;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
  }
  
  .image-container img {
    margin: 10px;
    width: auto;
    padding: 50px;
    max-height: 300px;
  }
  }